.mde-preview-content-user {
  display: flex;
  flex-direction: column;
  text-align: justify;
  font-weight: 400;
  p {
    color: #a3a3a3;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    display: block !important;
  }
  li {
    color: #a3a3a3;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    display: block !important;
  }
  h1 {
    display: block !important;
    font-size: 3em !important;
    font-weight: bold !important;
  }
  h2 {
    display: block !important;
    font-size: 2.25rem !important;
    font-weight: bold !important;
  }
  h3 {
    display: block !important;
    font-size: 1.875rem !important;
    font-weight: bold !important;
  }
  h4 {
    display: block !important;
    font-size: 1.5rem !important;
    font-weight: bold !important;
  }
  a {
    color: #60a5fa;
    text-decoration: underline;
  }
  a:visited {
    color: #606dfa;
  }
  a:hover {
    color: #6999ff;
  }
  a:active {
    color: #7c60fa;
  }

  p em {
    font-style: oblique;
  }
  p del {
    color: #000;
  }
}

@media only screen and (max-width: 767px) {
  .mde-preview-content-user h1 {
    font-size: 1.875rem !important;
    word-break: break-all;
  }
  .mde-preview-content-user h2 {
    font-size: 1.5rem !important;
    word-break: break-all;
  }
  .mde-preview-content-user h3 {
    font-size: 1.25em !important;
    word-break: break-all;
  }
  .mde-preview-content-user h4 {
    font-size: 1em !important;
    word-break: break-all;
  }
}
