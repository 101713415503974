@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  :root {
    --rmdp-primary-yellow: #fccc10 !important;
    --rmdp-secondary-yellow: #87898b !important;
    --rmdp-shadow-yellow: #dfdd61;
    --rmdp-today-yellow: #afac02;
    --rmdp-hover-yellow: #ffeb3b;
    --rmdp-deselect-yellow: #cca701;
    --primary: #fccc10;
    --primary-50: #fde581;
    --primary-200: #fde581;
    --primary-600: #c9a203;
    --button-text-primary: #000000;
  }

  .saudi-national-day,
  .saudi-national-day:hover {
    --primary: #00b4b3;
    --primary-50: #00e2e1;
    --primary-600: #007776;
    --button-text-primary: #ffffff;
  }

  html {
    background-color: #012424;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition:
      background-color 600000s 0s,
      color 600000s 0s;
  }
  input[data-autocompleted] {
    background-color: transparent !important;
  }

  .gradient-border {
    position: relative;
    padding: 15px 20px;
  }
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .gradient-border::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    border: 1.083px solid transparent;
    background: linear-gradient(
        160deg,
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.1)
      )
      border-box;
    -webkit-mask:
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
  img {
    -webkit-user-drag: none;
    -ms-user-select: none; /* IE 10+ */
    -moz-user-select: none; /* Firefox all */
    -webkit-user-select: none; /* Chrome all / Safari all */
    user-select: none; /* Likely future */
  }
  .gradient-border2 {
    position: relative;
    padding: 10px;
  }
  .gradient-border2::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 16px;
    border: 1.083px solid transparent;
    background: linear-gradient(
        160deg,
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.1)
      )
      border-box;
    -webkit-mask:
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

  .gradient-border3 {
    position: relative;
    padding: 15px 20px;
  }

  .gradient-border3:hover:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-radius: 16px;
    border: 3px solid transparent !important;

    background: linear-gradient(
        160deg,
        rgba(80, 197, 177, 0.5),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0),
        rgba(80, 197, 177, 0.5)
      )
      border-box;
    -webkit-mask:
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
  .gradient-borderChild:hover:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 9px;
    border: 1px solid transparent !important;

    background: linear-gradient(
        160deg,
        rgba(80, 197, 177, 0.5),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0),
        rgba(80, 197, 177, 0.5)
      )
      border-box;
    -webkit-mask:
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
  .gradient-border4 {
    position: relative;
    padding: 15px 20px;
  }

  .gradient-border4:hover:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    border: 1.9px solid transparent !important;
    transition: all 0.5s;
    background: linear-gradient(
        160deg,
        rgba(80, 197, 177, 0.5),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0),
        rgba(80, 197, 177, 0.5)
      )
      border-box;
    -webkit-mask:
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

  .gradient-border5 {
    position: relative;
  }

  .gradient-border5::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    border: 1.083px solid transparent;
    background: linear-gradient(
        160deg,
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.1)
      )
      border-box;
    -webkit-mask:
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
}
.gradient-border6 {
  position: relative;
}

.gradient-border6:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 1px;
  border: 1px solid transparent !important;
  transition: all 0.5s;
  background: linear-gradient(
      160deg,
      rgba(80, 197, 177, 0.5),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(80, 197, 177, 0.5)
    )
    border-box;
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

html {
  background-color: #000000;
}
.grecaptcha-badge {
  visibility: hidden;
}

@font-face {
  font-family: 'HelveticaLight';
  src: url('../public/assets/fonts/Helvetica.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'HelveticaCEMedium';
  src: url('../public/assets/fonts/HelveticaCEMedium.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'HelveticaCEBold';
  src: url('../public/assets/fonts/HelveticaCEBold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'HelveticaBold';
  src: url('../public/assets/fonts/Helvetica-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'HelveticaBoldOblique';
  src: url('../public/assets/fonts/Helvetica.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'ArbFONTS-bold';
  src: url('../public/assets/fonts/ArbFONTS-MyriadArabic-Bold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'ArbFONTS-Regular';
  src: url('../public/assets/fonts/ArbFONTS-MyriadArabic-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@keyframes loading {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -10px;
  display: block;
  width: 10px;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 13px;
  padding: 0;
  cursor: pointer;
  transition: width 0.3s ease-in-out;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 0px;
  height: 0px;

  content: '•';
  text-align: center;
  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

/* Custom Slick Dots */

@keyframes loading {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

.ft-slick__dots--custom {
  height: 4px;
  width: 10px;
  background-color: #7f8183;
  border-radius: 4px;
  position: relative;
}

.slick-dots li {
  padding-left: 4px;
}

.slick-dots .slick-active {
  width: 20px;
  transition: width 0.3s ease-in-out;
}

.slick-dots .slick-active .ft-slick__dots--custom {
  width: 20px;
  top: 0px;
}

.slick-dots .slick-active .ft-slick__dots--custom .loading {
  height: 4px;
  background-color: #fccc10; /* Set the background color directly */
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Ensure the loading bar fills the entire width */
  border-radius: 4px;
}
.custom-bg-blur {
  background: var(--Neutral-500-10, rgba(115, 115, 115, 0.1));
  /* backdrop-filter: blur(107.1px); */
}

.gradient-card-border:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 50px;
  border: 1px solid transparent;
  background: linear-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.1)
    )
    border-box;
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.gradient-card-border {
  position: relative;
  overflow: hidden;
}

.gradient-card-border1:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 8px;
  border: 1px solid transparent;
  background: linear-gradient(
      160deg,
      rgba(80, 197, 177, 0.5),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(80, 197, 177, 0.5)
    )
    border-box;
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.gradient-card-border1 {
  position: relative;
  overflow: hidden;
}
@variants responsive {
  .windows-os .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .windows-os .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .windows-os .yellow .rmdp-day.rmdp-selected span:not(.highlight) {
    color: black;
  }

  .windows-os .yellow .rmdp-day.rmdp-today span:not(.highlight) {
    background-color: #fccc10;
    color: black;
  }

  .windows-os .yellow {
    color: #fccc10 !important;
  }

  .windows-os .bg-dark {
    background-color: #000000 !important;
  }

  .windows-os html {
    scroll-behavior: smooth;
  }

  .windows-os ::-webkit-scrollbar {
    width: 12px;
  }

  .windows-os ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .windows-os ::-webkit-scrollbar-track {
    background: transparent;
  }

  .windows-os html,
  .windows-os body {
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
  }

  .windows-os .dropdown-list {
    position: absolute;
    background-color: #737373;
    overflow-y: auto;
    z-index: 1;
    top: 100%;
    left: 0;
  }
}
